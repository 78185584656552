<template>
<b-container class="m-0 w-100" fluid>
    <header-slot>
      <template #actions>
        <div>
          <b-button
              v-if="moduleId != 11"
              @click="changeStatusModalCreateNcrRealtor(true)"
              variant="success" >
            <feather-icon icon="PlusIcon" size="16" />
            CREATE
          </b-button>
        </div>
      </template>
    </header-slot>

    <b-nav
        card-header
        pills
        class="m-0">
        <b-nav-item
            exact
            :exact-active-class="statusNCR == 0 ? 'active' :''"
            @click="refreshTable"
            :to="{name: 'connection-ncr-realtor-in-process'}"
            :link-classes="['px-3',bgTabsNavs]">IN PROCESS
        </b-nav-item>
        <b-nav-item
            exact
            :exact-active-class="statusNCR == 1 ? 'active' :''"
            @click="refreshTable"
            :to="{name: 'connection-ncr-realtor-completed'}"
            :link-classes="['px-3',bgTabsNavs]">COMPLETED
        </b-nav-item>
    </b-nav>
    <table-ncr-realtor @table="assignTable($event)" />

    <modal-create-ncr-realtor
            v-if="isActiveModalCreateNcrRealtor"
            :typencr="0"
            @closeModalCreateNcrRealtor="changeStatusModalCreateNcrRealtor(false)"
            @refreshTable="refreshTable"
            />


</b-container>
</template>
<script>
import TableNcrRealtor from '@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue'
// import ModalCreateNcrRealtor from '@/views/commons/components/ncr-realtor/views/components/modals/ModalCreateNcrRealtor.vue'

import ModalCreateNcrRealtor from '@/views/commons/components/clients/dashboard/information-client/modals/ModalCreateNcrRealtor.vue'

export default {
    components:{ TableNcrRealtor,ModalCreateNcrRealtor },
    data(){return {
        table : null,
        isActiveModalCreateNcrRealtor: false,
    }},
    methods:{
        assignTable(table){
            this.table = table
        },
        refreshTable(){
            this.table.refresh()
        },
        changeStatusModalCreateNcrRealtor(visible){
            this.isActiveModalCreateNcrRealtor = visible
        },
    },
    computed:{
        statusNCR(){
            return this.$route.meta.statusNCR
        },
        moduleId(){
            return this.$route.matched[0].meta.module
        },
    },
}
</script>
<style scoped>
.tab{
    transform: translateY(-1rem);
}
.nav-item a {
    margin-bottom: 0 !important;
}
.container-fluid{
    padding: 0;
}
</style>
